import { render, staticRenderFns } from "./DeliveryModule.vue?vue&type=template&id=318f495e&scoped=true"
import script from "./DeliveryModule.vue?vue&type=script&lang=js"
export * from "./DeliveryModule.vue?vue&type=script&lang=js"
import style0 from "./DeliveryModule.vue?vue&type=style&index=0&id=318f495e&prod&lan=scss&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "318f495e",
  null
  
)

export default component.exports