
<template>
  <v-container class="order-status-container" fluid>
      <v-row class="h-100 d-flex" justify="center" align="center">
        <v-col cols="12" md="8" class="text-center">
          <v-img src="@/assets/order-success.png" class="status-icon mx-auto"></v-img>
          <h1 class="status-title">Order Successful!</h1>
          <p class="status-message">
              Thank you so much for your order! We'll send you a shipping confirmation email.
          </p>
          <v-btn color="#fe734a" class="status-button" @click="goBack">
            Back to Store
          </v-btn>
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
export default {
  name: "OrderCancel",
  methods: {
    goBack() {
      this.$router.push("/");
    }
  }
};
</script>

<style scoped>
.order-status-container {
  background-color: #fff !important;
  color: #000 !important;
  padding: 2rem !important;
  text-align: center !important;
}

.status-icon {
  width: 250px;
  height: 250px;
  margin-bottom: 1rem;
}

.status-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 1rem;
}

.status-message {
  font-size: 18px;
  margin-bottom: 2rem;
}

.status-button {
  background-color: #fe734a;
  color: white;
  font-size: 16px;
  font-weight: bold;
}
</style>